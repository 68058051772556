<template>
  <div class="q-pa-md" style="max-width: 1500px">
    
    <q-list bordered>
      <q-toolbar class="bg-primary text-white shadow-2">
        <q-toolbar-title>공정사고조사보고서 정보</q-toolbar-title>
      </q-toolbar>
      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>1. 일반사항</q-item-label>
          <br>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사업장 : " }} {{ accidentInfo.plantName }}</q-item-label>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사고발생일시 : " }} {{ accidentInfo.year2 }}</q-item-label>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사고발생장소 : " }} {{ accidentInfo.attNm }}</q-item-label>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">&nbsp;&nbsp;&nbsp;{{ "사고조사기간 : " }} {{ accidentInfo.year }}</q-item-label>
        </q-item-section>
        <q-item-section side top>
          <c-btn label="공정사고보고서 출력" icon="assignment" size="sm" @btnClicked="btnRecord" />
        </q-item-section>
      </q-item>

      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>2. 공정사고의 형태</q-item-label>
          <q-item-label caption lines="2">
             <c-radio
                :editable="editable"
                :comboItems="col2Items"
                :disabled="true"
                label=""
                name="accTypeCd"
                v-model="accidentInfo.accTypeCd"
              >
            </c-radio>
          </q-item-label>
        </q-item-section>

        <q-item-section avatar>
          <q-avatar color="teal" text-color="white" icon="bluetooth" />
        </q-item-section>
      </q-item>

      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>3. 공정사고의 상황개요</q-item-label>
          <br>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ accidentInfo.col1 }}</span>
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-avatar rounded color="purple" text-color="white" icon="bluetooth" />
        </q-item-section>
      </q-item>

      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>4. 공정사고의 발생원인</q-item-label>
          <br>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ accidentInfo.col7 }}</span>
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-avatar color="primary" text-color="white">
            R
          </q-avatar>
        </q-item-section>
      </q-item>

      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>5. 사고피해</q-item-label>
          <br>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ accidentInfo.col2 }}</span>
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-avatar color="primary" text-color="white">
            R
          </q-avatar>
        </q-item-section>
      </q-item>

      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>6. 비상조치사항</q-item-label>
          <br>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ accidentInfo.col3 }}</span>
          </q-item-label>

        </q-item-section>
        <q-item-section avatar>
          <q-avatar color="primary" text-color="white">
            R
          </q-avatar>
        </q-item-section>
      </q-item>
      
      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>7. 공정사고 재발장지를 위한 장·단기 대책</q-item-label>
          <br>
          <q-item-label caption lines="3" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ "단기 대책 : " }} {{ accidentInfo.col5 }}</span>
            <br>
            <br>
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ "중장기 대책 : " }} {{ accidentInfo.col4 }}</span>
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-avatar color="primary" text-color="white">
            R
          </q-avatar>
        </q-item-section>
      </q-item>

      <q-separator spaced inset />

      <q-item clickable v-ripple>
        <q-item-section>
          <q-item-label>8. 담당자 의견</q-item-label>
          <br>
          <q-item-label caption lines="2" class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase">
            <span class="cursor-pointer">&nbsp;&nbsp;&nbsp;{{ accidentInfo.col6 }}</span>
            </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-avatar color="primary" text-color="white">
            R
          </q-avatar>
        </q-item-section>
      </q-item>


      <q-separator spaced inset />

    </q-list>
    <!-- <c-card title="1. 일반사항" class="cardClassDetailForm" bgClass="">
      <template slot="card-button">
          <q-btn-group outline >
              <c-btn label="공정사고보고서 출력" icon="assignment" @btnClicked="btnRecord" />
          </q-btn-group>
        </template>
      <template slot="card-detail">
        <div class="col-md-4 col-lg-3">
          <c-label-text title="LBLPLANT" :value="accidentInfo.plantName"></c-label-text>
        </div>
        <div class="col-md-4 col-lg-3">
          <c-label-text title="사고발생일시" :value="accidentInfo.year2"></c-label-text>
        </div>
        <div class="col-md-4 col-lg-3">
          <c-label-text title="사고발생장소" :value="accidentInfo.attNm"></c-label-text>
        </div>
        <div class="col-md-4 col-lg-3">
          <c-label-text title="사고조사기간(일시)" :value="accidentInfo.year"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="2. 공정사고의 형태" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-4 col-lg-4">
          <c-radio
            :editable="editable"
            :comboItems="col2Items"
            :disabled="true"
            label=""
            name="col2"
            v-model="accidentInfo.col2"
          >
          </c-radio>
        </div>
      </template>
    </c-card>
    <c-card title="3. 공정사고의 상황개요" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-6 col-lg-6">
          <c-label-text title="상황개요" :value="accidentInfo.col1"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="4. 공정사고의 발생원인" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-6 col-lg-6" >
          <c-label-text title="발생원인" :value="accidentInfo.col7"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="5. 사고피해" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-6 col-lg-6" >
          <c-label-text title="사고피해" :value="accidentInfo.col2"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="6. 비상조치사항" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-6 col-lg-6">
          <c-label-text title="비상조치사항" :value="accidentInfo.col3"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="7. 공정사고 재발장지를 위한 장/단기 대책" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-6 col-lg-6">
          <c-label-text title="단기 대책" :value="accidentInfo.col4"></c-label-text>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-label-text title="중장기 대책" :value="accidentInfo.col5"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="8. 담당자 의견" class="cardClassDetailForm" bgClass=""> 
      <template slot="card-detail">
        <div class="col-md-6 col-lg-6">
          <c-label-text title="담당자 의견" :value="accidentInfo.col6"></c-label-text>
        </div>
      </template>
    </c-card> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'hazop-plan-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      accidentInfo: {
        plantName: '1사업장',
        request: '2021-08-22',
        attNm: '1시설',
        year: '2021-08-24',
        year2: '2021-08-22',
        userId: '',
        deptCd: '',
        accTypeCd: '1', 
        col1: '- 누가 : 홍길동 - 누구에게 : 김기철  \n- 언제 : 2021-08-24 \n- 어디서 : 1시설 \n- 무엇을 :  \n- 어떻게 : \n- 왜 : ',
        col2: '사고피해사고피해사고피해 사고피해',
        col3: '비상조치사항 비상조치사항 비상조치사항비상조치사항',
        col4: '중장기대책 중장기대책 중장기대책 중장기대책',
        col5: '단기대책 단기대책 단기대책 단기대책',
        col6: '담당자의견 담당자의견 담당자의견 담당자의견',
        col7: '발생원인발생원인발생원인발생원인발생원인'
      },
      col2Items: [
        { code: '1', codeName: '폭발' },
        { code: '2', codeName: '화재' },
        { code: '3', codeName: '위험물질누출' },
        { code: '4', codeName: '기타' },
        { code: '5', codeName: '누출물질' },
      ],
      searchUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    btnRecord() {
      this.popupOptions.target = () =>
      import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.param = this.selectedRow;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
