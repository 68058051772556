var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "q-pa-md", staticStyle: { "max-width": "1500px" } },
    [
      _c(
        "q-list",
        { attrs: { bordered: "" } },
        [
          _c(
            "q-toolbar",
            { staticClass: "bg-primary text-white shadow-2" },
            [_c("q-toolbar-title", [_vm._v("공정사고조사보고서 정보")])],
            1
          ),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("1. 일반사항")]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _vm._v(
                        "   " +
                          _vm._s("사업장 : ") +
                          " " +
                          _vm._s(_vm.accidentInfo.plantName)
                      ),
                    ]
                  ),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _vm._v(
                        "   " +
                          _vm._s("사고발생일시 : ") +
                          " " +
                          _vm._s(_vm.accidentInfo.year2)
                      ),
                    ]
                  ),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _vm._v(
                        "   " +
                          _vm._s("사고발생장소 : ") +
                          " " +
                          _vm._s(_vm.accidentInfo.attNm)
                      ),
                    ]
                  ),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _vm._v(
                        "   " +
                          _vm._s("사고조사기간 : ") +
                          " " +
                          _vm._s(_vm.accidentInfo.year)
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { side: "", top: "" } },
                [
                  _c("c-btn", {
                    attrs: {
                      label: "공정사고보고서 출력",
                      icon: "assignment",
                      size: "sm",
                    },
                    on: { btnClicked: _vm.btnRecord },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("2. 공정사고의 형태")]),
                  _c(
                    "q-item-label",
                    { attrs: { caption: "", lines: "2" } },
                    [
                      _c("c-radio", {
                        attrs: {
                          editable: _vm.editable,
                          comboItems: _vm.col2Items,
                          disabled: true,
                          label: "",
                          name: "accTypeCd",
                        },
                        model: {
                          value: _vm.accidentInfo.accTypeCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.accidentInfo, "accTypeCd", $$v)
                          },
                          expression: "accidentInfo.accTypeCd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c("q-avatar", {
                    attrs: {
                      color: "teal",
                      "text-color": "white",
                      icon: "bluetooth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("3. 공정사고의 상황개요")]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v("   " + _vm._s(_vm.accidentInfo.col1)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c("q-avatar", {
                    attrs: {
                      rounded: "",
                      color: "purple",
                      "text-color": "white",
                      icon: "bluetooth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("4. 공정사고의 발생원인")]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v("   " + _vm._s(_vm.accidentInfo.col7)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "q-avatar",
                    { attrs: { color: "primary", "text-color": "white" } },
                    [_vm._v(" R ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("5. 사고피해")]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v("   " + _vm._s(_vm.accidentInfo.col2)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "q-avatar",
                    { attrs: { color: "primary", "text-color": "white" } },
                    [_vm._v(" R ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("6. 비상조치사항")]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v("   " + _vm._s(_vm.accidentInfo.col3)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "q-avatar",
                    { attrs: { color: "primary", "text-color": "white" } },
                    [_vm._v(" R ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [
                    _vm._v("7. 공정사고 재발장지를 위한 장·단기 대책"),
                  ]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "3" },
                    },
                    [
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v(
                          "   " +
                            _vm._s("단기 대책 : ") +
                            " " +
                            _vm._s(_vm.accidentInfo.col5)
                        ),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v(
                          "   " +
                            _vm._s("중장기 대책 : ") +
                            " " +
                            _vm._s(_vm.accidentInfo.col4)
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "q-avatar",
                    { attrs: { color: "primary", "text-color": "white" } },
                    [_vm._v(" R ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
          _c(
            "q-item",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              attrs: { clickable: "" },
            },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v("8. 담당자 의견")]),
                  _c("br"),
                  _c(
                    "q-item-label",
                    {
                      staticClass:
                        "q-mt-xs text-body2 text-weight-bold text-primary text-uppercase",
                      attrs: { caption: "", lines: "2" },
                    },
                    [
                      _c("span", { staticClass: "cursor-pointer" }, [
                        _vm._v("   " + _vm._s(_vm.accidentInfo.col6)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "q-avatar",
                    { attrs: { color: "primary", "text-color": "white" } },
                    [_vm._v(" R ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-separator", { attrs: { spaced: "", inset: "" } }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }